import React from 'react';
import Img1 from '../../../assets/images/header-6.webp';
import ServiceNav from '../section/ServiceNav';

const SocialMediaMarketing = () => {
    return (
        <>
            <div className='w-full'>
                <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-16 my-8 overflow-hidden">
                    <div className='grid grid-cols-1 md:grid-cols-3'>
                        <div className='col-span-2'>
                            <h1 className='leading-tight text-[70px]'>Search Engine Optimization Enhance Your User Experience</h1>
                        </div>
                        <div className='col-span-1'>
                            <img src={Img1} alt="" className='rounded-full' />
                        </div>
                    </div>
                    <div className='m-auto w-[500px] absolute right-[10rem] mt-8'>
                        <p>Lift Your Rankings on SERPs and Get High Audience Engagement & Traffic on Your Website with Dynamic SEO Strategies at Affordable Prices.</p>
                    </div>
                    <ServiceNav/>                       
                </div>
            </div>
        </>
    );
};

export default SocialMediaMarketing;
