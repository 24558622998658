import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faTasks, faUsers } from '@fortawesome/free-solid-svg-icons'; 
import CodeImage from '../../assets/images/heroSection.webp';
import { motion } from 'framer-motion';

const CasestudySection = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [progress, setProgress] = useState(0); 

  const tabData = [
    {
      title: ' Ed’s Plant Shop',
      icon: faTasks, 
      description: "Ed's Plant Shop offers a wide range of Plants, Garden Tools, and Professional Landscaping Services in New York.",
    },
    {
      title: 'The Attractive Man',
      icon: faCoffee, 
      description: 'The Attractive Man Coaching is a premier coaching service specializing in helping men improve their dating and relationship skills.',
    },
    {
      title: 'Kawaii Kart',
      icon: faUsers, 
      description: 'A leading Online Retailer that specializes in selling a wide range of Toys, Collectibles, Action Figures and more.',
    }
  ];

  useEffect(() => {
    const intervalTime = 10000; 
    const stepTime = 50; 
    const totalSteps = intervalTime / stepTime;
    let currentStep = 0;

    const interval = setInterval(() => {
      currentStep += 1;
      setProgress((currentStep / totalSteps) * 100);

      if (currentStep >= totalSteps) {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % tabData.length); 
        currentStep = 0; 
        setProgress(0); 
      }
    }, stepTime);

    return () => clearInterval(interval); 
  }, [selectedIndex]);
  return (
    <>
      <div className='w-full bg-gray-100'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-28 pt-16 overflow-hidden'>
          <h2>Streamline workflows, track progress, and meet deadlines with ease.</h2>
        </div>
      </div>
      <div className='w-full bg-gray-100'>
        <div className='lg:py-28 py-8 overflow-hidden md:pl-[6rem] px-[2rem] md:px-[0rem] '>
          <Tabs selectedIndex={selectedIndex} onSelect={(index) => { setSelectedIndex(index); setProgress(0); }}>
            <div className='grid grid-cols-1 md:grid-cols-5 md:gap-x-16 gap-x-0'>
              <div className='col-span-2'>
                <TabList className="border-none">
                  {tabData.map((tab, index) => (
                    <Tab
                      key={index}
                      className="border-none focus:outline-none cursor-pointer"
                      style={{ outline: 'none' }}
                    >
                      <div
                        className={`p-8 mb-2 transition-all duration-300 ease-in-out cursor-pointer ${selectedIndex === index ? 'bg-white rounded-2xl' : 'bg-transparent'}`}
                        style={{
                          position: 'relative',
                          borderBottom: '2px solid transparent',
                        }}
                      >
                        <div className='flex'>
                          <div className='mt-2'>
                          <span className='text-[25px]'>
                            <FontAwesomeIcon icon={tab.icon} />
                          </span>
                          </div>
                          <div className='ml-4 '>
                          <h3>{tab.title}</h3>
                          {selectedIndex === index && <p className='mt-2'>{tab.description}</p>}
                          </div>
                        </div>
                        {selectedIndex === index && (
                          <div
                            style={{
                              position: 'absolute',
                              bottom: 0,
                              left: 0,
                              height: '2px',
                              width: `${progress}%`,
                              backgroundImage: 'linear-gradient(to right, orange, red, purple)', 
                              transition: 'width 0.05s linear',
                            }}
                          />
                        )}
                      </div>
                    </Tab>
                  ))}
                </TabList>
              </div>
              <div className='col-span-3'>
                {tabData.map((tab, index) => (
                  <TabPanel key={index}>
                    {selectedIndex === index && (
                      <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.5 }}
                        className='bg-white md:p-20 md:pr-0 p-10 md:rounded-tl-3xl md:rounded-bl-3xl rounded-3xl md:rounded-none'
                      >
                        <img src={CodeImage} alt="" className='border-4 rounded-xl border-gray-100' />
                      </motion.div>
                    )}
                  </TabPanel>
                ))}
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default CasestudySection;
