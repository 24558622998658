import React, { useState, useEffect } from 'react';
import HeroSectionAllPage from './section/HeroSectionAllPage';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchData, getImageUrl } from '../../services/apiService'; 

const CaseStudies = () => {
  const [caseStudies, setCaseStudies] = useState([]); 

  useEffect(() => {
    const fetchCaseStudies = async () => {
      try {
        const data = await fetchData('case-studies'); 
        setCaseStudies(data); 
      } catch (error) {
        console.error('Error fetching case studies:', error);
      }
    };

    fetchCaseStudies();
  }, []);

  const renderCaseStudies = () => {
    const caseStudiesToRender = [];
    let index = 0;
    while (index < caseStudies.length) {
      caseStudiesToRender.push(
        <div key={caseStudies[index].id} className="my-8">
          <Link to={`/case-study/${caseStudies[index].id}`}>
            <motion.div
              className="relative overflow-hidden rounded-3xl"
              whileHover={{ scale: 0.95 }} 
              transition={{ duration: 0.3 }}
            >
              <img
                src={getImageUrl(caseStudies[index].image)}
                alt={caseStudies[index].title}
                className="rounded-3xl w-full object-cover"
              />
              <div className="absolute bottom-0 w-full bg-gradient-to-t from-black via-transparent to-transparent p-4">
                <h3 className="text-white">{caseStudies[index].title}</h3>
              </div>
            </motion.div>
          </Link>
        </div>
      );
      index++; 

      if (index < caseStudies.length) {
        caseStudiesToRender.push(
          <div key={`pair-${index}`} className="flex justify-center gap-8">
            <div>
              <Link to={`/case-study/${caseStudies[index].id}`}>
                <motion.div
                  className="relative overflow-hidden rounded-3xl"
                  whileHover={{ scale: 0.95 }} 
                  transition={{ duration: 0.3 }}
                >
                  <img
                    src={getImageUrl(caseStudies[index].image)}
                    alt={caseStudies[index].title}
                    className="rounded-3xl w-full object-cover"
                  />
                  <div className="absolute bottom-0 w-full bg-gradient-to-t from-black via-transparent to-transparent p-4">
                    <h3 className="text-white">{caseStudies[index].title}</h3>
                  </div>
                </motion.div>
              </Link>
            </div>

            {index + 1 < caseStudies.length && (
              <div>
                <Link to={`/case-study/${caseStudies[index + 1].id}`}>
                  <motion.div
                    className="relative overflow-hidden rounded-3xl"
                    whileHover={{ scale: 0.95 }} 
                    transition={{ duration: 0.3 }}
                  >
                    <img
                      src={getImageUrl(caseStudies[index + 1].image)}
                      alt={caseStudies[index + 1].title}
                      className="rounded-3xl w-full object-cover"
                    />
                    <div className="absolute bottom-0 w-full bg-gradient-to-t from-black via-transparent to-transparent p-4">
                      <h3 className="text-white">{caseStudies[index + 1].title}</h3>
                    </div>
                  </motion.div>
                </Link>
              </div>
            )}
          </div>
        );
        index += 2; 
      }
    }

    return caseStudiesToRender;
  };

  return (
    <>
      <HeroSectionAllPage />
      <div className="w-full">
        <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-16 my-8 overflow-hidden">
          <div>
            <h2 className="text-[80px]">World-class digital products, idea to execution.</h2>
          </div>

          {/* Render case studies */}
          {renderCaseStudies()}
        </div>
      </div>
    </>
  );
};

export default CaseStudies;
