import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/navigation/Header';
import Home from './components/home/Home';
import AboutUs from './components/pages/AboutUs';
import Footer from './components/navigation/Footer';
import SingleCaseStudy from './components/home/SingleCaseStudy';
import SinglePost from './components/home/SinglePost';
import Solutions from './components/pages/Solutions';
import CaseStudies from './components/pages/CaseStudies';
import Blog from './components/pages/Blog';
import Contact from './components/pages/Contact';
import SearchEngineOptimization from './components/pages/services/SearchEngineOptimization';
import SocialMediaMarketing from './components/pages/services/SocialMediaMarketing';
import TermsOfService from './components/pages/TermsOfService';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import GDPR from './components/pages/GDPR';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="/posts/:id" element={<SinglePost />} />
        <Route path="/case-study/:id" element={<SingleCaseStudy />} />
        <Route path="solutions" element={<Solutions/>} />
        <Route path="case-studies" element={<CaseStudies/>} />
        <Route path="casedemo" element={<SingleCaseStudy/>} />
        <Route path="blog" element={<Blog/>} />
        <Route path="contact" element={<Contact/>} />
        <Route path="solutions/seo" element={<SearchEngineOptimization/>} />
        <Route path="solutions/smm" element={<SocialMediaMarketing/>} />
        <Route path="term-of-service" element={<TermsOfService/>} />
        <Route path="privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="gdrp" element={<GDPR/>} />
      </Routes>
      <Footer/>
    </Router>
    
  );
}

export default App;
