import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Logo from '../../assets/images/adtoboxLogo.webp';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import Button from '../pages/section/Button';
import PopupForm from '../home/PopupForm';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null); 
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); 

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  return (
    <>
     <div className='w-full sticky top-10 z-50  lg:px-[6rem] px-[1rem]'>
    <div className={`container mx-auto rounded-full transition-colors duration-300 ${isScrolled ? 'bg-white' : 'gradient-bg'}`}>
      <div className="flex items-center justify-between gap-4 mt-8 px-4 md:px-0">
        <div className="p-4">
          <img src={Logo} alt="Adtobox Logo" width={130} />
        </div>

        {/* Desktop menu */}
        <div className="hidden md:flex p-4">
          <ul className="flex gap-4 justify-center text-[16px] relative">
            {['Home', 'About', 'Solutions', 'Case Studies', 'Blog', 'Contact'].map((item, i) => {
              const path = item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`;
              const isActive = location.pathname === path; // Check if the route is active

              return (
                <li key={i} className="relative group">
                  {/* Animated background */}
                  <motion.div
                    className={`absolute inset-0 rounded-full ${isActive ? 'bg-black' : 'bg-gray-200 rounded-full'}`}
                    initial={{ width: '0%' }}
                    animate={{
                      width: isActive || hoveredIndex === i ? '100%' : '0%',
                      transition: { duration: 0.3 },
                    }}
                  />
                  <Link
                    to={path}
                    className={`relative block px-4 py-2 text-black transition-colors duration-300 ${isActive ? 'text-orange-400' : ''} ${hoveredIndex === i ? 'bg-gray-200 rounded-full' : 'group-hover:bg-gray-200 group-hover:rounded-full'}`}
                    onMouseEnter={() => setHoveredIndex(i)} // Set hover index
                    onMouseLeave={() => setHoveredIndex(null)} // Reset hover index
                  >
                    {item}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>

        {/* Desktop button */}
        <div className="hidden md:block p-4">
         <Button label='Get Started' size="medium"  padding={{ top: 3, right: 10, bottom: 3, left: 10 }} onClick={togglePopup}/>
        </div>

        {/* Mobile menu toggle */}
        <div className="md:hidden p-4">
          <button onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black z-40"
            onClick={toggleMobileMenu}
          />
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ duration: 0.3 }}
            className="fixed top-0 right-0 w-full h-full bg-white z-50 px-6 py-8"
          >
            <div className="flex justify-between items-center">
              <h1 className="text-[1.5rem] font-semibold">Menu</h1>
              <button onClick={toggleMobileMenu}>
                <FaTimes size={24} />
              </button>
            </div>

            <ul className="flex flex-col gap-6 text-[16px] mt-10 text-black">
              {['Home', 'About', 'Solutions', 'Case Studies', 'Blog', 'Contact'].map((item, i) => {
                const path = item === 'Home' ? '/' : `/${item.toLowerCase().replace(' ', '-')}`;
                return (
                  <li key={i}>
                    <Link to={path} onClick={toggleMobileMenu} className="block py-2 hover:bg-gray-200 rounded-full">{item}</Link>
                  </li>
                );
              })}
            </ul>
            <div className="mt-6">
            <Button label='Get Started' size="medium"  padding={{ top: 3, right: 10, bottom: 3, left: 10 }} onClick={togglePopup}/>
            </div>
          </motion.div>
        </>
      )}
    </div>
    <PopupForm isOpen={isOpen} togglePopup={togglePopup} />
   </div>
    </>
  );
};

export default Header;
