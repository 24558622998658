import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import TestimonialSlider from './Testimonials';
import Faqs from './Faqs';
import Blog from './Blog';
import CasestudySection from './CasestudySection';
import { fetchData } from '../../services/apiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import Solutions from './Solutions';
import Image1 from '../../assets/images/woo-1-1536x767.jpg';
import Image2 from '../../assets/images/imgdemo.jpg';
import Image3 from '../../assets/images/gridimg4.webp';
import Image4 from '../../assets/images/Google_Fonts_logo2-1.webp'
import Image5 from '../../assets/images/helpcenter-salient-1.webp'
import Partner1 from '../../assets/images/awwwards.webp';
import Partner2 from '../../assets/images/hongkiat.webp';
import Partner3 from '../../assets/images/templatic.webp';
import MefaMenu from '../../assets/images/megamenu-2.webp';
import HeroSectionAllPage from '../pages/section/HeroSectionAllPage';
import Button from '../pages/section/Button';


library.add(fas);

const IMAGE_BASE_URL = 'https://admin.ad2box.com/public/images';
const getImageUrl = (imagePath) => `${IMAGE_BASE_URL}/${imagePath}`;
const items = ["150k websites", "industry leaders", "top designers"];

function Home() {
  const [sections, setSections] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [count, setCount] = useState(0);

  // Fetch all required data at once
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [section1Data, section2Data] = await Promise.all([
          fetchData('section1'),
          fetchData('section2'),
        ]);
        setSections(section2Data || []);
      } catch {
        console.error("Error fetching data");
      }
    };
    fetchAllData();
  }, []);

  // Consolidate interval updates
  useEffect(() => {
    const countInterval = setInterval(() => {
      setCount((prev) => (prev < 97 ? prev + 1 : prev));
    }, 50);

    const indexInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 2000);

    return () => {
      clearInterval(countInterval);
      clearInterval(indexInterval);
    };
  }, []);

  return (
    <>
      <HeroSectionAllPage />
      <div className="w-full relative">
        <motion.div
          className="flex gap-20 justify-center my-16"
          animate={{ x: ["0%", "-50%"] }}
          transition={{ repeat: Infinity, duration: 50, ease: "linear" }}
        >
          {[Partner1, Partner2, Partner3, Partner1, Partner2, Partner3].map((src, index) => (
            <div key={index}><img src={src} alt={`Partner${index + 1}`} /></div>
          ))}
        </motion.div>

        <div className="container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden relative z-10">
          <div className="grid grid-cols-1 md:grid-cols-5">
            <div className="col-span-1"></div>
            <div className="col-span-3 text-center">
              <h2>Trusted by</h2>
              <motion.h2
                key={currentIndex}
                className="reveal-text"
                initial={{ rotateY: 90, opacity: 0 }}
                animate={{ rotateY: 0, opacity: 1 }}
                exit={{ rotateY: -90, opacity: 0 }}
                transition={{ duration: 1.9 }}
              >
                {items[currentIndex]}
              </motion.h2>
            </div>
            <div className="col-span-1"></div>
          </div>
        </div>
      </div>

      <div className='w-full'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden mt-28'>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>

            <div className='bg-gray-100 p-8 rounded-3xl'>
              <h3>PPC Management</h3>
              <p>Wasting money on ads that don’t convert? Our targeted PPC strategies focus on reaching
                high-intent customers, optimizing every campaign with in-depth keyword research and A/B testing.
                It’s not just about clicks — it’s about driving quality traffic that leads to sustainable growth.
              </p>
              <img src={Image3} alt="" />
            </div>

            <div className='bg-blue-500 p-8 rounded-3xl text-white'>
              <h3>Website Development</h3>
              <p>Struggling with high bounce rates? We design websites that do more than look good.
                By understanding your brand and customer journey, we create fast, intuitive sites that
                engage visitors and convert them into loyal customers.
              </p>
              <img src={MefaMenu} alt="" className='mt-8' />
            </div>

            <div className='bg-yellow-500 p-8 rounded-3xl text-white'>
              <h3>Analytics & Insights</h3>
              <p>Making decisions without data is risky. Our analytics go beyond basic metrics to
                uncover actionable insights into your ad performance and website behavior.
                This empowers you to refine strategies, optimize budgets, and drive better results.
              </p>
              <img src={MefaMenu} alt="" className='mt-8' />
            </div>

          </div>
        </div>
      </div>

      <div className='w-full'>
        <div className='container mx-auto px-[1rem] lg:px-[4rem] overflow-hidden'>
          <div className='bg-gray-100 rounded-3xl mt-8 p-6 lg:p-0 pb-0'>
            <div className='grid grid-cols-1 md:grid-cols-5 gap-4'>
              <div className='col-span-1'></div>
              <div className='col-span-3 text-center lg:mt-16 mt-8'>
                <h3>Powerful WooCommerce Functionality</h3>
                <p>Unlock incredible store building potential with an AJAX off-canvas cart,
                  product quickview, advanced reviews, premium gallery styles, collapsable product widget area,
                  and much more.</p>
                <div className='m-auto flex justify-center mt-16'>
                  <Button label='Get Started' size="medium" padding={{ top: 3, right: 10, bottom: 3, left: 10 }} />
                </div>
              </div>
              <div className='col-span-1'></div>
            </div>
            <div className='lg:mt-16 mt-8 lg:px-[10rem] px-[1rem]'>
              <img src={Image2} alt="" width={"200px"} className='absolute right-[8rem] mt-[5rem] rounded-xl max-[768px]:hidden' />
              <img src={Image1} alt="" className='rounded-tl-3xl rounded-tr-3xl' />
            </div>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-5 gap-8 mt-8'>
            <div className='col-span-2 flex flex-col'>
              <div className='bg-yellow-400 p-8 rounded-3xl flex-1'>
                <h3>Free Updates for Life</h3>
                <p>Over the past 10 years, we’ve consistently released updates with new features and fixes. You’ll get free access to all future updates with a single purchase.</p>
                <img src={Image3} alt="" className='mt-4' />
              </div>
            </div>
            <div className='col-span-3 flex flex-col'>
              <div className='flex bg-blue-500 p-8 rounded-3xl text-white gap-x-10 flex-1'>
                <div>
                  <h3>Google Fonts</h3>
                  <p>Choose from over 1,400 Google fonts with powerful responsive typography settings.</p>
                </div>
                <div>
                  <img src={Image4} alt="" />
                </div>
              </div>
              <div className='bg-gray-100 p-8 rounded-3xl gap-x-10 mt-8'>
                <h3>Blazing Performance</h3>
                <p>Salient is Built with modern tooling and includes a suite of simple to use, yet powerful
                  performance options created to target page speed scoring. View the report from GTMetrix or
                  Google Lighthouse.</p>
                <div className='mt-8'>
                  <Button label='Get Started' size="medium" padding={{ top: 3, right: 10, bottom: 3, left: 10 }} />
                </div>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mt-8'>
            <div className='bg-gray-100 rounded-3xl'>
              <div className='p-8'>
                <h3>Success Probability Calculator </h3>
                <p><span className='text-[20px] font-semibold'>Purpose:</span> Engage visitors with a personalized assessment tool to estimate how successful a PPC campaign or website redesign could be for their business.
                </p>
              </div>
              <img src={Image5} alt="" className='rounded-bl-3xl rounded-br-3xl' />
            </div>
            <div className='bg-card-home p-8 rounded-3xl text-white'>
              <h3>What’s Your Marketing Score?</h3>
              <p><span className='text-[20px] font-semibold'>Purpose: </span>Provide an engaging way for visitors to assess their current marketing performance and identify areas of improvement.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:my-28 my-16'>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-8'>
          <div>
            <h5>WHAT WE DO</h5>
            <h3>Driving success through strategic paid search advertising!</h3>
            <a href='#'>KNOW MORE ABOUT US</a>
          </div>
          <div className='col-span-1 md:col-span-2'>
            <p>Transforming your brand's online presence and generating meaningful results is our top priority.</p>
            <div className='grid md:grid-cols-2 lg:grid-cols-2 gap-14 mt-12'>
              {sections.map((sec, i) => (
                <div key={i} className='p-4'>
                  <FontAwesomeIcon icon={sec.icon} className='text-[2.5rem] p-5 bg-gray-100 rounded-full text-orange-400' />
                  <h4 className='my-4'>{sec.title}</h4>
                  <p>{sec.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='w-full relative'>
        <TestimonialSlider />
        <Solutions />
        <CasestudySection />
        <Faqs />
        <Blog />
      </div>
    </>
  );
}

export default Home;
