import React, { useEffect, useState, Suspense } from 'react';
import { fetchData } from '../../services/apiService';
import { Link } from 'react-router-dom';
import HeroSectionAllPage from './section/HeroSectionAllPage';

const IMAGE_BASE_URL = 'https://admin.ad2box.com/public/images';
const getImageUrl = (imagePath) => `${IMAGE_BASE_URL}/${imagePath}`;

const LazyImage = React.memo(({ src, alt }) => (
  <img
    src={src}
    alt={alt}
    className="rounded-xl w-full h-auto transition-transform transform hover:scale-105 duration-500"
  />
));

const Blog = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const getBlogData = async () => {
      try {
        const data = await fetchData('posts');
        setBlogData(data);
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    getBlogData();
  }, []);

  return (
    <>
    <HeroSectionAllPage/>
    <div className="w-full relative">
      <div className="container mx-auto px-4 lg:px-16 lg:my-24 my-8">
        {/* First Row (Design 1) */}
        <div className='grid grid-cols-1 md:grid-cols-8 gap-16'>
          {/* First design */}
          {blogData[0] && (
            <div className='col-span-1 md:col-span-4 animate__animated animate__fadeInLeft'>
              <Link to={`/posts/${blogData[0].id}`}>
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyImage
                    src={getImageUrl(blogData[0].image)}
                    alt="Blog 1"
                  />
                </Suspense>
                <div className='mt-8'>
                  <span>{blogData[0].date}</span>
                  <h3 className='text-[24px] my-2'>{blogData[0].title}</h3>
                  <p>{blogData[0].description}</p>
                </div>
              </Link>
            </div>
          )}

          {/* Smaller posts */}
          {blogData.slice(1, 3).map((blog, index) => (
            <div className='col-span-1 md:col-span-2 animate__animated animate__fadeInUp' key={index}>
              <Link to={`/posts/${blog.id}`}>
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyImage
                    src={getImageUrl(blog.image)}
                    alt="Blog"
                  />
                </Suspense>
                <div className='mt-8'>
                  <span>{blog.date}</span>
                  <h3 className='text-[24px] my-2'>{blog.title}</h3>
                  <p>{blog.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>

        {/* Additional rows with alternating layout */}
        {blogData.slice(3).map((_, rowIndex) => {
          const startIndex = 3 + rowIndex * 4;
          const rowDesign = rowIndex % 2 === 0 ? 'Design 2' : 'Design 1';

          return (
            <div className='grid grid-cols-1 md:grid-cols-8 gap-16 mt-16' key={rowIndex}>
              {rowDesign === 'Design 1' && blogData[startIndex] && (
                <>
                  <div className='col-span-1 md:col-span-4 animate__animated animate__fadeInLeft'>
                    <Link to={`/posts/${blogData[startIndex].id}`}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <LazyImage
                          src={getImageUrl(blogData[startIndex].image)}
                          alt="Blog"
                        />
                      </Suspense>
                      <div className='mt-8'>
                        <span>{blogData[startIndex].date}</span>
                        <h3 className='text-[24px] my-2'>{blogData[startIndex].title}</h3>
                        <p>{blogData[startIndex].description}</p>
                      </div>
                    </Link>
                  </div>
                  {blogData.slice(startIndex + 1, startIndex + 3).map((post, idx) => (
                    <div className='col-span-1 md:col-span-2 animate__animated animate__fadeInUp' key={idx}>
                      <Link to={`/posts/${post.id}`}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <LazyImage
                            src={getImageUrl(post.image)}
                            alt="Blog"
                          />
                        </Suspense>
                        <div className='mt-8'>
                          <span>{post.date}</span>
                          <h3 className='text-[24px] my-2'>{post.title}</h3>
                          <p>{post.description}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </>
              )}

              {rowDesign === 'Design 2' && (
                <>
                  {blogData.slice(startIndex, startIndex + 2).map((post, idx) => (
                    <div className='col-span-1 md:col-span-2 animate__animated animate__fadeInUp' key={idx}>
                      <Link to={`/posts/${post.id}`}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <LazyImage
                            src={getImageUrl(post.image)}
                            alt="Blog"
                          />
                        </Suspense>
                        <div className='mt-8'>
                          <span>{post.date}</span>
                          <h3 className='text-[24px] my-2'>{post.title}</h3>
                          <p>{post.description}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                  {blogData[startIndex + 2] && (
                    <div className='col-span-1 md:col-span-4 animate__animated animate__fadeInLeft'>
                      <Link to={`/posts/${blogData[startIndex + 2].id}`}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <LazyImage
                            src={getImageUrl(blogData[startIndex + 2].image)}
                            alt="Blog"
                          />
                        </Suspense>
                        <div className='mt-8'>
                          <span>{blogData[startIndex + 2].date}</span>
                          <h3 className='text-[24px] my-2'>{blogData[startIndex + 2].title}</h3>
                          <p>{blogData[startIndex + 2].description}</p>
                        </div>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
    </>
  );
};

export default Blog;
