import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import GreenRating from '../../assets/images/GreenRating.png';
import OrangeRating from '../../assets/images/OrangeRating.png'
import YellowRating from '../../assets/images/yellowRating.png'

const testimonials = [
    {
        id: 1,
        name: 'John Doe',
        title: 'CEO of Company',
        testimonial: 'Work closely with clients to understand their business goals, target audience, and budget. Develop a comprehensive paid search strategy',
    },
    {
        id: 2,
        name: 'Jane Smith',
        title: 'CTO of Startup',
        testimonial: '"Jupiter X makes best use of the strong infrastructure, high performance, coding standards and design flexibility that Elementor offers."',
    },
    {
        id: 3,
        name: 'Sam Wilson',
        title: 'Freelancer',
        testimonial: '"Compared to the competition on ThemeForest, I found this theme much more pleasant to use than most of its competitors, such as Avada and Bridge." ',
    },
];


const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const delay = 5000;

    useEffect(() => {
        const interval = setInterval(() => {
            nextTestimonial();
        }, delay);

        return () => clearInterval(interval);
    }, [currentIndex]);

    const nextTestimonial = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
        );
    };

    return (
        <>
            <div className="relative mx-auto space-y-12 bg-image py-8 lg:py-28">
                <div className="relative">
                    <motion.h2
                        className="text-center text-[2rem] lg:text-[5rem] font-semibold mb-8 text-transparent bg-clip-text"
                        style={{
                            backgroundImage: "linear-gradient(90deg, #FF007F, #FFDD00, #50C878, #007FFF)",
                        }}
                        initial={{ backgroundSize: "200% 200%" }}
                        animate={{ backgroundPosition: ["100% 0%", "0% 0%"] }}
                        transition={{
                            duration: 5,
                            repeat: Infinity,
                            ease: "linear",
                        }}
                    >
                        Just amazing...
                    </motion.h2>
                </div>
                <div className="relative overflow-hidden ">
                    <div className="relative w-full h-60 ">
                        <AnimatePresence>
                            <motion.div
                                key={testimonials[currentIndex].id}
                                initial={{ opacity: 0, x: 100 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -100 }}
                                transition={{ duration: 0.5 }}
                                className="absolute inset-0 flex text-center items-center justify-center p-4"
                            >
                                <div className='px-[2rem] lg:px-[5rem]'>
                                    <p className="text-[1rem] lg:text-[2rem] font-semibold">"{testimonials[currentIndex].testimonial}"</p>
                                    <h4 className="mt-4 font-semibold">{testimonials[currentIndex].name}</h4>
                                    <p className="text-sm text-gray-500">{testimonials[currentIndex].title}</p>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>
                    <button
                        onClick={prevTestimonial}
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 px-4 py-2 bg-white rounded-full hover:bg-gray-200 transition ml-[1rem] lg:ml-16"
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button
                        onClick={nextTestimonial}
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 px-4 py-2 bg-white rounded-full hover:bg-gray-200 transition mr-[1rem] lg:mr-16"
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </div>
            {/* Image Marquee Section */}
            <div className='w-full bg-[#F5F5F7] h-[800px] overflow-hidden'>
     <div className='container mx-auto px-[1rem] lg:px-[4rem] pt-8 overflow-hidden'>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-8'>
            <div className="block-33 display--inline-top">
                <div className="gutter relative">
                <div className="absolute top-[-50px] left-0 w-full h-[200px] bg-gradient-to-b from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                <div className="absolute top-[36rem] left-0 w-full h-[200px] bg-gradient-to-t from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                    <div className="gallery-h">
                        <div className='col-span-1'>
                            {[
                                { name: "John Doe", text: "This theme has transformed my website! The functionality is incredible, and I appreciate the continuous updates that enhance its performance.",image: GreenRating },
                                { name: "Jane Smith", text: "I am extremely satisfied with this theme. The design is modern, and it provides an excellent user experience that keeps my visitors coming back.", image: YellowRating },
                                { name: "Robert Brown", text: "Highly recommend this theme! The support team is responsive, and the recent updates have made it even better than before." , image: GreenRating},
                                { name: "Emily Davis", text: "An absolute game-changer! The features are intuitive, and I've been able to achieve the exact look I wanted for my site.", image: OrangeRating},
                                { name: "Michael Johnson", text: "I've tried many themes before, but this one stands out due to its customization options and overall quality. It's worth every penny!" , image: YellowRating },
                                { name: "John Doe", text: "This theme has transformed my website! The functionality is incredible, and I appreciate the continuous updates that enhance its performance.", image: YellowRating },
                                { name: "Jane Smith", text: "I am extremely satisfied with this theme. The design is modern, and it provides an excellent user experience that keeps my visitors coming back.", image: GreenRating },
                                { name: "Robert Brown", text: "Highly recommend this theme! The support team is responsive, and the recent updates have made it even better than before.", image: GreenRating },
                                { name: "Emily Davis", text: "An absolute game-changer! The features are intuitive, and I've been able to achieve the exact look I wanted for my site.", image: OrangeRating },
                                { name: "Michael Johnson", text: "I've tried many themes before, but this one stands out due to its customization options and overall quality. It's worth every penny!", image: OrangeRating }
                            ].map((item, index) => (
                                <div className="gallery-image relative" key={index}>
                                    <div className="gallery-image__img relative">
                                        <div className='bg-white p-8 rounded-xl card-shadow'>
                                            <h4 className='mb-2 text-[14px] font-bold'>{item.name}</h4>
                                            <img src={item.image} alt="" />
                                            <p className='text-[14px] mt-2'>{item.text}</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>

            <div className="block-33 display--inline-top">
                <div className="gutter relative">
                <div className="absolute top-[-50px] left-0 w-full h-[200px] bg-gradient-to-b from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                <div className="absolute top-[36rem] left-0 w-full h-[200px] bg-gradient-to-t from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                    <div className="gallery-h gallery-h--tb">
                        <div className='col-span-1'>
                            {[
                                { name: "Alice Wilson", text: "The best theme I have used! It offers great flexibility and the updates are always so helpful and timely." , image: YellowRating},
                                { name: "David Lee", text: "Incredible performance! My website loads faster, and the design options are simply the best I've encountered." , image: OrangeRating },
                                { name: "Sophia Martinez", text: "I love this theme! It's not only beautiful but also functional. The customer service is exceptional as well.", image: GreenRating },
                                { name: "James Miller", text: "This theme makes it easy to create a stunning website without any coding knowledge. Highly recommend it!" , image: OrangeRating },
                                { name: "Olivia Taylor", text: "Fantastic theme with lots of features! It has everything I need to make my website stand out.", image: GreenRating },
                                { name: "Alice Wilson", text: "The best theme I have used! It offers great flexibility and the updates are always so helpful and timely." , image: OrangeRating },
                                { name: "David Lee", text: "Incredible performance! My website loads faster, and the design options are simply the best I've encountered.", image: GreenRating },
                                { name: "Sophia Martinez", text: "I love this theme! It's not only beautiful but also functional. The customer service is exceptional as well.", image: YellowRating },
                                { name: "James Miller", text: "This theme makes it easy to create a stunning website without any coding knowledge. Highly recommend it!" , image: OrangeRating },
                                { name: "Olivia Taylor", text: "Fantastic theme with lots of features! It has everything I need to make my website stand out." , image: GreenRating}
                            ].map((item, index) => (
                                <div className="gallery-image relative" key={index}>
                                    <div className="gallery-image__img relative">
                                        <div className='bg-white p-8 rounded-xl card-shadow'>
                                        <h4 className='mb-2 text-[14px] font-bold'>{item.name}</h4>
                                            <img src={item.image} alt="" />
                                            <p className='text-[14px] mt-2'>{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {/* Repeat the above pattern for the remaining blocks */}
            {/* You can add more blocks as needed using similar structure */}

            <div className="block-33 display--inline-top">
                <div className="gutter relative">
                <div className="absolute top-[-50px] left-0 w-full h-[200px] bg-gradient-to-b from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                <div className="absolute top-[36rem] left-0 w-full h-[200px] bg-gradient-to-t from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                    <div className="gallery-h">
                        <div className='col-span-1'>
                            {[
                                { name: "Liam Harris", text: "I was amazed at how easy it was to set up and customize my website with this theme. It has exceeded my expectations!" , image: YellowRating},
                                { name: "Ava Thompson", text: "A beautiful theme that provides everything I need for my online store. I've seen a noticeable increase in sales!" , image: YellowRating},
                                { name: "Mason White", text: "Highly customizable and feature-rich. It makes managing my website a breeze.", image: YellowRating },
                                { name: "Isabella Moore", text: "Great theme, highly recommended! The support team was very helpful during my setup process.", image: OrangeRating  },
                                { name: "Ethan Jackson", text: "I love the modern design and user-friendly interface. It's perfect for my blog!" , image: GreenRating},
                                { name: "Liam Harris", text: "I was amazed at how easy it was to set up and customize my website with this theme. It has exceeded my expectations!" , image: YellowRating},
                                { name: "Ava Thompson", text: "A beautiful theme that provides everything I need for my online store. I've seen a noticeable increase in sales!", image: OrangeRating  },
                                { name: "Mason White", text: "Highly customizable and feature-rich. It makes managing my website a breeze." , image: YellowRating},
                                { name: "Isabella Moore", text: "Great theme, highly recommended! The support team was very helpful during my setup process.", image: GreenRating },
                                { name: "Ethan Jackson", text: "I love the modern design and user-friendly interface. It's perfect for my blog!" , image: GreenRating}
                            ].map((item, index) => (
                                <div className="gallery-image relative" key={index}>
                                    <div className="gallery-image__img relative">
                                        <div className='bg-white p-8 rounded-xl card-shadow'>
                                        <h4 className='mb-2 text-[14px] font-bold'>{item.name}</h4>
                                            <img src={item.image} alt="" />
                                            <p className='text-[14px] mt-2'>{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="block-33 display--inline-top">
                <div className="gutter relative">
                <div className="absolute top-[-50px] left-0 w-full h-[200px] bg-gradient-to-b from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                <div className="absolute top-[36rem] left-0 w-full h-[200px] bg-gradient-to-t from-[#F5F5F7] to-transparent pointer-events-none z-10"></div>
                    <div className="gallery-h gallery-h--tb">
                        <div className='col-span-1'>
                            {[
                                { name: "Charlotte Hall", text: "This theme has made a significant difference to my website. The layout is clean, and it's so easy to navigate.", image: YellowRating },
                                { name: "Lucas Allen", text: "Excellent theme! I've received many compliments on the design. The flexibility is unmatched.", image: YellowRating },
                                { name: "Mia Young", text: "This theme has everything I need and more! The customization options are fantastic." , image: YellowRating},
                                { name: "Benjamin King", text: "A great investment for any business. It has boosted my online presence significantly." , image: YellowRating},
                                { name: "Harper Wright", text: "I can't say enough good things about this theme! It's user-friendly and looks amazing." , image: YellowRating},
                                { name: "Charlotte Hall", text: "This theme has made a significant difference to my website. The layout is clean, and it's so easy to navigate." , image: GreenRating},
                                { name: "Lucas Allen", text: "Excellent theme! I've received many compliments on the design. The flexibility is unmatched.", image: OrangeRating  },
                                { name: "Mia Young", text: "This theme has everything I need and more! The customization options are fantastic." , image: GreenRating},
                                { name: "Benjamin King", text: "A great investment for any business. It has boosted my online presence significantly.", image: YellowRating },
                                { name: "Harper Wright", text: "I can't say enough good things about this theme! It's user-friendly and looks amazing.", image: OrangeRating  }
                            ].map((item, index) => (
                                <div className="gallery-image relative" key={index}>
                                    <div className="gallery-image__img relative">
                                        <div className='bg-white p-8 rounded-xl card-shadow'>
                                        <h4 className='mb-2 text-[14px] font-bold'>{item.name}</h4>
                                            <img src={item.image} alt="" />
                                            <p className='text-[14px] mt-2'>{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


        </>
    );
};

export default TestimonialSlider;
