import React from 'react';
import { Link } from 'react-router-dom';
import HeroSectionAllPage from './section/HeroSectionAllPage';
import Img1 from '../../assets/images/google-search-console.webp';
import Img2 from '../../assets/images/1-1.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';


const sections = [
  {
    title: "Digital Marketing",
    description: "Stuck in the digital dust? Our expert team ... achieve meaningful results!",
    items: [
      [
        { name: "Search Engine Optimization", url: "/solutions/seo" },
        { name: "Search Engine Marketing", url: "/sem" }
      ],
      [
        { name: "Content Marketing", url: "/content-marketing" },
        { name: "Social Media Strategy", url: "/social-media-strategy" }
      ]
    ],
    images: [Img1, Img2, Img1, Img2]
  },
  {
    title: "Digital Marketing",
    description: "Stuck in the digital dust? Our expert team ... achieve meaningful results!",
    items: [
      [
        { name: "Search Engine Optimization", url: "/solutions/seo" },
        { name: "Search Engine Marketing", url: "/sem" }
      ],
      [
        { name: "Content Marketing", url: "/content-marketing" },
        { name: "Social Media Strategy", url: "/social-media-strategy" }
      ]
    ],
    images: [Img1, Img2, Img1, Img2]
  },
  {
    title: "Digital Marketing",
    description: "Stuck in the digital dust? Our expert team ... achieve meaningful results!",
    items: [
      [
        { name: "Search Engine Optimization", url: "/solutions/seo" },
        { name: "Search Engine Marketing", url: "/sem" }
      ],
      [
        { name: "Content Marketing", url: "/content-marketing" },
        { name: "Social Media Strategy", url: "/social-media-strategy" }
      ]
    ],
    images: [Img1, Img2, Img1, Img2]
  },
];

const Solutions = () => {
  return (
    <>
      <HeroSectionAllPage />
      <div className="w-full">
        <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-16 my-8 overflow-hidden">
          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="border-t-2 border-gray-300 pt-8 mt-16">
              <h2 className="mb-4">{section.title}</h2>
              <p>{section.description}</p>

              <div className="grid grid-cols-1 md:grid-cols-2 mt-8 items-center">
                {section.items.map((col, colIndex) => (
                  <div key={colIndex}>
                    <ul className="font-semibold">
                      {col.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <Link to={item.url} className="text-[24px]">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-orange-400 text-[24px] mr-[10px]" />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-4 gap-10 mt-16 img-solution-shadow pb-8">
                {section.images.map((image, imgIndex) => (
                  <motion.img
                    key={imgIndex}
                    src={image}
                    alt=""
                    className="rounded-lg"
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Solutions;
