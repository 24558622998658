import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import PopupForm from '../home/PopupForm';
import { Link } from 'react-router-dom';


const changingWords = ["Epic", "Wonderful", "Connect"];

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentWord, setCurrentWord] = useState(changingWords[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % changingWords.length);
      setCurrentWord(changingWords[index]);
    }, 4000);
    return () => clearInterval(interval);
  }, [index]);

  const togglePopup = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <footer className='w-full relative overflow-hidden bg-[#0c0c0c] text-[#c1c1c1]'>
      <div className='container mx-auto px-[1rem] lg:px-[4rem] lg:pt-24 md:pb-32 py-8 '>
        <div className="text-[60px] font-medium cursor-pointer hover:text-orange-400 custom-hover" onClick={togglePopup}>
          <h2 className='text-[60px] font-medium flex items-center'>
            Let’s make
            <FontAwesomeIcon 
              icon={faArrowRight} 
              className="ml-2 text-orange-400 transition-transform duration-300 ease-in-out"
            />
          </h2>
          <div className='mt-[-20px]'>
            <span> Something </span>
            <span className='ticker'>{currentWord}</span>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-4 mt-32 gap-16">
          <div>
          <h4 className="mb-2">Speak to us</h4>
          <p><a href="tel:+918810734094">+91 8810 734 094</a></p>
          <p><a href="mailto:hello@ad2box.com">hello@ad2box.com</a></p>
          </div>
          <div>
            <h4 className="mb-2">India</h4>
            <p>174, Satrikh Road, Chinhat, LKO, Uttar Pradesh, India. 226028</p>
          </div>
          <div>
            <h4 className="mb-2">USA</h4>
            <p>2443 Sierra Nevada Road, Mammoth Lakes CA, USA. 93546 </p>
          </div>
          <div>
            <h4 className="mb-2">Australia</h4>
            <p>73 Warren Avenue, Killingworth, New South Wales (NSW), AUS. 2278</p>
          </div>
         
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 border-t border-orange-400 mt-16 pt-8'>
          <div>
            <p>© 2024 Ad2Box ALL RIGHTS RESERVED.</p>
          </div>
          <div>
          <ul className='flex gap-10 justify-end items-center'>
            <li><Link to="term-of-service">Terms of Service</Link></li>
            <li><Link to="privacy-policy">Privacy Policy</Link></li>
            <li><Link to="gdrp">GDPR</Link></li>
          </ul>
          </div>
          
        </div>
      </div>
      <PopupForm isOpen={isOpen} togglePopup={togglePopup} />
    </footer>
  );
};

export default Footer;
