import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; 
import Blog1 from '../../assets/images/filters_quality(80).webp'; 

const Blog = () => {
  const blogPosts = [
    { id: 1, date: '23.03.24', title: 'Unleashing Business Success: The Crucial Role of Tracking Marketing KPIs' },
    { id: 2, date: '23.03.24', title: 'The Future of Marketing: Trends and Innovations for 2024' },
    { id: 3, date: '23.03.24', title: 'How to Create Effective Customer Retention Strategies' },
    { id: 4, date: '23.03.24', title: 'Top 5 Digital Marketing Metrics You Should Track' },
    { id: 5, date: '23.03.24', title: 'Understanding Consumer Behavior in 2024: Key Insights' },
    { id: 6, date: '23.03.24', title: 'Building a Strong Brand Identity: Strategies for Success' },
    { id: 7, date: '23.03.24', title: 'The Role of Data in Modern Marketing Campaigns' },
    { id: 8, date: '23.03.24', title: 'Maximizing ROI on Digital Marketing Investments' },
    { id: 9, date: '23.03.24', title: 'Leveraging Social Media for Business Growth in 2024' },
    { id: 10, date: '23.03.24', title: 'Email Marketing Strategies to Boost Engagement' },
  ];


  return (
    <div className='w-full relative  overflow-hidden'> 
    <div className='bg-gray-100'>
      <div className='mx-auto py-24'>
        <h2 className='px-[4rem]'>What's New</h2>
        <div className="swiper-wrapper-container"> 
          <Swiper
            slidesPerView={1.2} 
            spaceBetween={20} 
            pagination={{ clickable: true }} 
            breakpoints={{
              640: {
                slidesPerView: 2.5, 
              },
              768: {
                slidesPerView: 3.5, 
              },
              1024: {
                slidesPerView: 4.5, 
              },
            }}
            className="mt-16 swiper-container"
          >
            {blogPosts.map((post) => (
              <SwiperSlide key={post.id}>
                <div className="swiper-slide-content">
                  <img src={Blog1} alt="Blog" className='rounded-3xl mb-4' />
                  <span>{post.date}</span>
                  <h4 className='mt-6'>{post.title}</h4>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Blog;
