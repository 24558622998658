import React from 'react';
import Img1 from '../../../assets/images/header-6.webp';
import Img2 from '../../../assets/images/Taskade.webp';
import Img3 from '../../../assets/images/Kawaii-Kart.webp';
import Img4 from '../../../assets/images/stat-love-brands-768x621.webp';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Button from '../section/Button';
import ServiceNav from '../section/ServiceNav';

const SearchEngineOptimization = () => {
    return (
        <div className='w-full'>
            <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-16 my-8 overflow-hidden">
                <div className='grid grid-cols-1 md:grid-cols-3'>
                    <div className='col-span-2'>
                        <h1 className='leading-tight text-[70px]'>Search Engine Optimization Enhance Your User Experience</h1>
                    </div>
                    <div className='col-span-1'>
                        <img src={Img1} alt="" className='rounded-full' />
                    </div>
                </div>
                <div className='m-auto w-[500px] absolute right-[10rem] mt-8'>
                    <p>Lift Your Rankings on SERPs and Get High Audience Engagement & Traffic on Your Website with Dynamic SEO Strategies at Affordable Prices.</p>
                </div>
                <ServiceNav/>
                <div className='grid md:grid-cols-4 mt-8 gap-8'>
                    <div className='col-span-2 flex flex-col gap-8'>
                        <img src={Img2} alt="" className='rounded-2xl flex-1 object-cover' />
                    </div>
                    <div className='col-span-1 flex flex-col gap-8'>
                        <img src={Img2} alt="" className='rounded-2xl object-cover flex-1' />
                        <img src={Img2} alt="" className='rounded-2xl object-cover flex-1' />
                    </div>
                    <div className='col-span-1 flex flex-col gap-8'>
                        <img src={Img2} alt="" className='rounded-2xl object-cover flex-1' />
                        <img src={Img2} alt="" className='rounded-2xl object-cover flex-1' />
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 mt-32 mb-20 gap-60 items-center'>
                    <div>
                        <h2>Cutting-edge websites coded to your goals</h2>
                    </div>
                    <div>
                        <p className='text-[20px]'>We’re Not Just Coders, We’re a Dream Team of Strategists, Designers, and Marketing Gurus. We Work Side-By-Side With You, Ensuring Your Website Aligns Perfectly With Your Vision.</p>
                    </div>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 items-center'>
                    <div className='mb-16 mr-32'>
                        <h3>Social Media Marketing</h3>
                        <p className='my-4'>AdToBox is Your Rescue Squad! We’re Social Media Wizards, Crafting Campaigns That Capture Eyes, Build Buzz, and Boost Your Business.</p>
                        <Button to="/" label='Find Out More' size="medium" padding={{ top: 3, right: 20, bottom: 3, left: 20 }} />
                    </div>
                    <div className='mb-16'>
                        <img src={Img3} alt="" className='rounded-3xl' />
                    </div>
                    <div className='mb-16'>
                        <img src={Img3} alt="" className='rounded-3xl' />
                    </div>
                    <div className='mb-16 ml-32'>
                        <h3>Social Media Marketing</h3>
                        <p className='my-4'>AdToBox is Your Rescue Squad! We’re Social Media Wizards, Crafting Campaigns That Capture Eyes, Build Buzz, and Boost Your Business.</p>
                        <Button to="/" label='Find Out More' size="medium" padding={{ top: 3, right: 20, bottom: 3, left: 20 }} />
                    </div>
                    <div className='mb-16 mr-32'>
                        <h3>Social Media Marketing</h3>
                        <p className='my-4'>AdToBox is Your Rescue Squad! We’re Social Media Wizards, Crafting Campaigns That Capture Eyes, Build Buzz, and Boost Your Business.</p>
                        <Button to="/" label='Find Out More' size="medium" padding={{ top: 3, right: 20, bottom: 3, left: 20 }} />
                    </div>
                    <div className='mb-16'>
                        <img src={Img3} alt="" className='rounded-3xl' />
                    </div>
                </div>

                <div>
                    <h2>Our Results</h2>
                    <p>The proof is in the pudding.</p>
                </div>

                {/* Swiper Slider for "Our Results" section */}
                  <Swiper
                slidesPerView={1}
                spaceBetween={20}
                loop={true}
                modules={[]}
                grabCursor={true} // Enables mouse control for slide
                className="mySwiper"
            >
                {[...Array(3)].map((_, index) => (
                    <SwiperSlide key={index}>
                        <div className='service-bg-result p-20 mt-8'>
                            <h4>Love Brands</h4>
                            <div className='flex justify-center items-center gap-20'>
                                <div>
                                    <h2>41.61%</h2>
                                    <p>increase in returning visitors after 6 months</p>
                                </div>
                                <div>
                                    <img src={Img4} alt="" />
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            </div>
        </div>
    );
};

export default SearchEngineOptimization;
