import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const ListItem = ({ text }) => (
  <li className="mb-4">
    <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
    {text}
  </li>
);
const PrivacyPolicy = () => {
  return (
   <>
    <div className="w-full overflow-hidden">
      <div className="container mx-auto px-[1rem] lg:px-[4rem] lg:my-16 my-8">
        <div className="grid grid-cols-1 md:grid-cols-5 relative">
          <motion.div
            className="col-span-4"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <h1>Privacy Policy</h1>
            <motion.div
              className="mt-16"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            >
              <h2 className="mb-4">Welcome to Ad2Box!</h2>
              <p className="mb-4">
                These terms and conditions outline the rules and regulations for the use of Ad2Box’s Website, located at ad2box.com.
              </p>
              <p className="mb-4">
                By accessing this website we assume you accept these terms and conditions. Do not continue to use Ad2Box if you do not agree to take all of the terms and conditions stated on this page.
              </p>

              <p className="mb-4">
                The following terminology applies to these Terms and Conditions, Privacy Statement, Disclaimer Notice, and all Agreements: “Client”, “You” and “Your” refers to you, the person logging on to this website and complying with the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our”, and “Us” refer to our Company. “Party”, “Parties”, or “Us” refer to both the Client and ourselves...
              </p>

              <h3 className="mb-4">Cookies</h3>
              <p className="mb-4">
                We employ the use of cookies. By accessing Ad2Box, you agreed to use cookies in agreement with the Ad2Box’s Privacy Policy.
              </p>
              <p className="mb-4">
                Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
              </p>
              <h3 className="mb-4">License</h3>
              <p className="mb-4">
                Unless otherwise stated, Ad2Box and/or its licensors own the intellectual property rights for all material on Ad2Box. All intellectual property rights are reserved. You may access this from Ad2Box for your own personal use subject to restrictions set in these terms and conditions.
              </p>
              <span className="mb-4 font-semibold text-[20px]">You must not:</span>
              <ul className="mb-4">
                <ListItem text="Republish material from Ad2Box" />
                <ListItem text="Sell, rent or sub-license material from Ad2Box" />
                <ListItem text="Reproduce, duplicate or copy material from Ad2Box" />
                <ListItem text="Redistribute content from Ad2Box" />
              </ul>
              <p className="mb-4">
                Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website...
              </p>
              <p className="mb-4">
                Ad2Box reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or cause a breach of these Terms and Conditions.
              </p>
              <span className="mb-4 font-semibold text-[20px]">You warrant and represent that:</span>
              <ul className="mb-4">
                <ListItem text="You are entitled to post the Comments on our website and have all necessary licenses and consents to do so" />
                <ListItem text="The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party" />
                <ListItem text="The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material" />
                <ListItem text="The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity" />
              </ul>
              <p className="mb-4">
                You hereby grant Ad2Box a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.
              </p>
            </motion.div>
          </motion.div>

          {/* Fixed Right Sidebar */}
          <div className="col-span-1 fixed right-[4rem] top-[30rem] w-[15%] p-4 hidden md:block">
            <FontAwesomeIcon icon={faArrowDown} className="float-right rotate-45 text-[7rem]" />
          </div>
        </div>
      </div>
    </div>
   </>
  )
}

export default PrivacyPolicy